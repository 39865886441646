//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cash from '@modules/cash/mixins/cash';
import CashWrapper from "./CashWrapper";
export default {
  name: 'RedeemSuccess',
  components: {
    CashWrapper: CashWrapper
  },
  mixins: [cash],
  computed: {
    alertInfo: function alertInfo() {
      return this.$store.getters['cash/alertInfo'];
    },
    currencySymbol: function currencySymbol() {
      return window.config.appMain.currencySymbol;
    }
  },
  methods: {
    close: function close() {
      this.modalCommandClose();
    }
  }
};